import ru from '../translations/ru';
import cz from '../translations/cz';
import de from '../translations/de';
import se from '../translations/se';
import lt from '../translations/lt';
import pl from '../translations/pl';

const availableTranslations = {
  ru,
  cz,
  de,
  se,
  lt,
  pl,
};

const defaultLanguage = 'en';

/**
 * Translate text into given language.
 */
export const translate = (value, langcode) => {
  let translatedValue = value;
  let translationExists = false;

  // Return original value for default language.
  if (langcode === defaultLanguage) {
    return translatedValue;
  }

  // Throw and error if language code is undefined.
  if (!availableTranslations.hasOwnProperty(langcode)) {
    console.error(`Translation file does not exist for langcode: ${langcode}`);
    return translatedValue;
  }

  // Replace with translation if exists in file.
  const translationsFile = availableTranslations[langcode];
  if (translationsFile.hasOwnProperty(value) && translationsFile[value] !== '') {
    translatedValue = translationsFile[value];
    translationExists = true;
  }

  // For translation file generation, see README file in translations folder.
  if (typeof localStorage !== "undefined" && localStorage.generateTranslations) {
    const translations = JSON.parse(localStorage.getItem('translations')) || [];

    let translation = translations.find( item => (
      item[defaultLanguage] === value
    ));

    if (!translation) {
      translation = {};
      translation[defaultLanguage] = value;

      translations.push(translation);
    }

    translation[langcode] = translationExists ? translatedValue : '';

    localStorage.setItem('translations', JSON.stringify(translations));
  }

  return translatedValue;
};
