import React, { Component, createRef } from 'react';
import {Grid, Image, Rail, Ref, Sticky, Card, Icon} from 'semantic-ui-react';
import { connect } from 'react-redux';
import TranslatedText from '../../01_atoms/TranslatedText';
import SendEmailForm from '../../02_molecules/SendEmailForm';

import './_styles.scss';
import divideNumber from '../../../utils/divideNumber';
import MobileSticky from "../../02_molecules/MobileSticky";
import getData from "../../../utils/getData";
import getCurrencySign from "../../../utils/getCurrencySign";
import getTotalPriceObj from "../../../utils/getTotalPriceObj";
import {translate} from "../../../utils/translations";
import getHouseLink from "../../../utils/getHouseLink";

class Summary extends Component {
  contextRef = createRef();

  render() {
    const { houseId, totalPriceWithVAT, totalPrice, vatPrice, country, language, totalPriceWithoutDiscount, totalDiscountTillDate } = this.props;
    const data = getData();
    const houseData = data['houses'][houseId];
    const houseModel = houseData.model.toUpperCase();
    // const houseModelNum = houseData.name.match(/[0-9]+/)[0];
    // const houseName = `${houseModel} ${houseModelNum}`;
    const houseName = `${houseData.name}`;

    const mobileWidth = 767;
    let windowWidth = 768;
    let houseLink = getHouseLink(data, houseId);

    if (typeof window !== 'undefined') {
      windowWidth = window.innerWidth;
    }

    return (
      <Ref innerRef={this.contextRef}>
        <>
          <Rail
            style={{ width: '100%', padding: '1rem' }}
            attached
            internal
            position="left"
          >
            <Sticky
              offset={24}
              context={this.contextRef}
              active={windowWidth > mobileWidth}
            >
              <div className="configuration">

                <Card fluid id="house_info">
                  <Card.Content id="house_content">
                    <Card.Header className='house-content-header'>
                      <span className="house_model">{houseName}</span>
                      {
                        houseLink &&
                        <a href={houseLink} target='_blank' className='house-model-link'>
                          <Icon name='info'/>
                          <span>{translate('To the site', language)}</span>
                        </a>
                      }
                    </Card.Header>
                    <Image src={houseData.image} className="house_image" />
                    <Grid
                      columns={2}
                      verticalAlign="bottom"
                      id="house-data"
                    >
                      <Grid.Column id="house_info_column">
                        <div className="info_header">
                          <TranslatedText text="Building area" />
                        </div>
                        <div className="info_value">
                          {`${houseData.area} ${translate('m²', language)}`}
                        </div>
                      </Grid.Column>
                      <Grid.Column id="house_info_column">
                        <div className="info_header">
                          <TranslatedText text="Useful area" />
                        </div>
                        <div className="info_value">
                          {`${houseData.useful_area} ${translate('m²', language)}`}
                        </div>
                      </Grid.Column>
                      {/*<Grid.Column id="house_info_column">*/}
                      {/*  <div className="info_header">*/}
                      {/*    <TranslatedText text="Veranda area" />*/}
                      {/*  </div>*/}
                      {/*  <div className="info_value">*/}
                      {/*    {`${houseData.veranda_area} ${translate('m²', language)}`}*/}
                      {/*  </div>*/}
                      {/*</Grid.Column>*/}
                    </Grid>
                  </Card.Content>
                </Card>

              <div className='price-container'>
                {totalPrice === totalPriceWithoutDiscount &&
                  <div className='main-column'>
                    <div className='price-header'><TranslatedText text='Price'/></div>
                    <div className='main-price-value'>{getCurrencySign()}{divideNumber(totalPrice, ' ')}</div>
                  </div>
                }
                {totalPrice !== totalPriceWithoutDiscount &&
                  <div className='main-column with-discount'>
                    <div className='price-header'><TranslatedText text='Price with discount'/></div>
                    <div className='main-price-value without-discount'>{getCurrencySign()}{divideNumber(totalPriceWithoutDiscount, ' ')}</div>
                    <div className='main-price-value'>{getCurrencySign()}{divideNumber(totalPrice, ' ')}</div>
                    {totalDiscountTillDate && <div className="discount-until-date">* <TranslatedText text='The discount is valid until'/> {totalDiscountTillDate}</div>}
                  </div>
                }
                {
                  !!vatPrice &&
                    <div className='additional-column'>
                      <div className='price-text'><TranslatedText text='VAT'/></div>
                      <div className='price-value'>{getCurrencySign()}{divideNumber(vatPrice, ' ')}</div>
                      <div className='price-text'><TranslatedText text='Full'/></div>
                      <div className='price-value'>{getCurrencySign()}{divideNumber(totalPriceWithVAT, ' ')}</div>
                    </div>
                }
              </div>

                <SendEmailForm />

                <div className="offer_text">
                  <div><TranslatedText text="The price is not an offer and valid for 5 days." /></div>
                  <div><TranslatedText text={`All rights reserved ${country === 'by' ? `HOMELAND` : `AIGERHOUSE`}, 2024.`} /></div>
                </div>
              </div>
            </Sticky>
          </Rail>
          <MobileSticky contextRef={this.contextRef} price={totalPrice} />
        </>
      </Ref>
    );
  }
}

const mapStateToProps = (store) => {
  const { houseId, country, language } = store;
  const { vatPrice, totalPrice, totalPriceWithVAT, totalPriceWithoutDiscount, totalDiscountTillDate } = getTotalPriceObj(store);

  return {
    language,
    country,
    houseId,
    totalPrice,
    totalPriceWithVAT,
    vatPrice,
    totalPriceWithoutDiscount,
    totalDiscountTillDate,
  };
};

export default connect(mapStateToProps)(Summary);
