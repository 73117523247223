import {translate} from "./translations";
import getData from "./getData";
import getCatalogLink from "./getCatalogLink";

export const getMailTextObj = (country, offerType, language, userName, userEmail) => {
  const data = getData();
  const countryName = data.configuration.countries[country].name;

  let headerLogo,
      companyName,
      mailSubject,
      mailBody,
      mailSignature,
      catalogLink,
      host = 'https://configurator.aigerhouse.com';

  if (typeof window !== "undefined") {
    host = window.location.origin;
  }

  headerLogo = host + "/images/aigerhouse-logo.png";

  // Default mail fields
  companyName = 'AIGERHOUSE';
  if (country === 'by') {
    companyName = 'HOMELAND'
  }

  mailSubject = `${companyName}: Offer ${offerType} ${countryName}`;
  mailBody = `${companyName} - ${translate('Manufacturer of wooden houses', language)}.<br>
                  ${translate('Commercial offer in the attachment', language)}.<br>
                  ${translate('A catalog with a description of the complete set of houses can be downloaded from the', language)}`;

  mailSignature = `
    SIA AIGERHOUSE<br>
    office@aigerhouse.com<br>
    http://www.aigerhouse.com
`;

  // Catalog download link
  catalogLink = host + getCatalogLink(country, language);

  // Mail for RU calculator
  if (country === 'spb' ||
      country === 'msk' ||
      country === 'krd') {

    mailSubject = `${companyName}: Розничное предложение ${translate(countryName, language)}`;

    mailSignature = `
      SIA AIGERHOUSE<br>
      office@aigerhouse.com<br>
      http://www.aigerhouse.ru
    `;
  }

  // Mail for BY calculator
  if (country === 'by') {
    headerLogo = host + "/images/homeLand-logo.png";

    mailSubject = `${companyName}: Розничное предложение ${translate(countryName, language)}`;

    mailSignature = `
      ООО "ХоумЛенд Групп"<br>
      РБ, г.Минск, ул.Мележа,1, оф.1605<br>
      +375 29 665-00-20 velcom<br>
      dv@homeland.by<br>
      www.homeland.by<br>
      <br>
      HOMELAND<br>
      И завтра Вы дома!
    `;
  }

  let mail = `${translate('Name', language)}: ${userName}<br>
                ${translate('E-Mail', language)}: ${userEmail}<br><br>
                ${mailBody} <a href="${catalogLink}" download="Catalog.pdf" target=”_blank”>${translate('link', language)}</a><br><br>`;


  let template = `
<!DOCTYPE html>
<html lang="${language}">
<head>
  <meta charset="UTF-8">
  <title>${companyName}</title>
</head>
<body style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >
<div class="body" style="background-color:#f3f3f5;color:#333333;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:14px;line-height:150%;" >
  <div class="container" style="max-width:600px;width:100%;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;padding-top:20px;padding-bottom:20px;padding-right:0;padding-left:0;" >
    <div class="header" style="padding-bottom:20px;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;width:min-content;" >
      <img src="${headerLogo}"
             alt=""
             width="165"
      />
    </div>

    <div class="main" style="padding-top:20px;padding-bottom:0;padding-right:35px;padding-left:35px;background-color:#ffffff;border-radius:10px 10px 0 0;" >
      ${mail}
    </div>

    <div class="footer" style="padding-top:20px;padding-bottom:20px;padding-right:20px;padding-left:20px;background-color:#ffffff;color:#9b9b9b;border-radius:0 0 10px 10px;" >
      <hr style="color:#d9d9d9;background-color:#d9d9d9;height:1px;border-style:none;" />
      ${mailSignature}
    </div>
  </div>
</div>
</body>
</html>
  `;

  return {
    subject: mailSubject,
    body: template,
  }
};