import React, {Component} from "react";
import {initialCustomOptionsBlock} from "../../../../utils/initialBlock";
import {connect} from "react-redux";
import {deleteBlockValue, updateBlockValue} from "../../../../store/actions";
import BlockContainer from "../../BlockContainer";
import {Button} from "semantic-ui-react";
import {inputFilter} from "../../../../utils/inputFilter";
import {translate} from "../../../../utils/translations";
import TranslatedText from "../../../01_atoms/TranslatedText";
import './_styles.scss'
import getCurrencySign from "../../../../utils/getCurrencySign";
import Input from "../../../01_atoms/Input";

class CustomOptions extends Component{

  constructor(props, context) {
    super(props, context);

    this.state = {};

    const { blockValue } = this.props;

    Object.keys(blockValue).forEach(id => {
      const option = blockValue[id];
      const { text, value } = option;

      this.state[id] = {
        text: text,
        value: value,
      }
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;
    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  handleChange (e) {
    const { name, value } = e.target;
    const { dispatch, blockValue, block } = this.props;
    const id = /\d+/.exec(name)[0];
    const field = /text|value/.exec(name)[0];

    let inputValue = inputFilter(value, 'negative_numbers');

    if (field === 'text') {
      inputValue = value;
    }

    const previousState = this.state[id];
    this.setState({[id]:{...previousState, [field]: inputValue}});

    inputValue = inputValue === '' ? 0 : Number(inputValue);

    if (field === 'text') {
      inputValue = value;
    }

    let newBlockValue = {...blockValue};
    newBlockValue[id][field] = inputValue;

    dispatch(updateBlockValue(block['machine_name'], newBlockValue))
  }

  handleAddClick() {
    const { dispatch, blockValue, block } = this.props;

    const optionsId = Object.keys(blockValue);
    const lastId = optionsId[optionsId.length - 1];

    const newId = typeof lastId === "undefined" ? 0 : Number(lastId) + 1;

    const newOption = {text: '', value: ''};

    this.setState({[newId]: newOption});

    let newBlockValue = {...blockValue};

    newBlockValue[newId] = newOption;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  handleRemoveClick(id) {
    const { dispatch, blockValue, block } = this.props;

    let newBlockValue = {...blockValue};
    delete newBlockValue[id];

    const state = Object.assign({}, this.state);
    delete state[id];
    this.setState(state);

    const optionsCount = Object.keys(blockValue).length;

    if (optionsCount === 1) {
      newBlockValue[0] = {text: '', value: ''};
      this.setState(newBlockValue);
    }

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  render() {
    const { blockValue, block, language } = this.props;
    const optionsId = Object.keys(blockValue);

    return (
      <BlockContainer
        image={block.image}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {optionsId.map((id) => {
          const {text, value} = this.state[id];

          return (
            <div className={`option-container`} key={id}>
              <div className='option' style={{width: '60%'}}>
                <Input
                  textAlign={'left'}
                  fluid
                  name={`${id}_text`}
                  value={translate(text, language)}
                  onChange={this.handleChange}
                  placeholder={`${translate('Option description', language)}`}
                />
              </div>
              <div className='option-price'>
                <Input
                  fluid
                  label={`${getCurrencySign()}`}
                  labelPosition={'left'}
                  name={`${id}_value`}
                  value={value}
                  onChange={this.handleChange}
                  placeholder={'0'}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="15"
                  height="15"
                  onClick={() => {this.handleRemoveClick(id)}}
                  className='option-delete-icon'
                >
                  <path d="M4.99 3.99a1 1 0 00-.697 1.717L10.586 12l-6.293 6.293a1 1 0 101.414 1.414L12 13.414l6.293 6.293a1 1 0 101.414-1.414L13.414 12l6.293-6.293a1 1 0 00-.727-1.717 1 1 0 00-.687.303L12 10.586 5.707 4.293a1 1 0 00-.717-.303z"/>
                </svg>
              </div>
            </div>
          )

        })}

          <Button
            style={{display:'flex', alignItems:'center', marginTop: '9px'}}
            onClick={this.handleAddClick}
            basic
            compact
          >
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                 style={{
                   overflow: 'visible',
                   marginRight: '5px'
                 }}
            >
              <g fill="none" fillRule="evenodd">
                <circle stroke="#0f5157" strokeWidth="2" fill="#FFF" cx="12" cy="12" r="9"/>
                <path fill="#0f5157" d="M11 7h2v10h-2z"/>
                <path fill="#0f5157" d="M17 11v2H7v-2z"/>
              </g>
            </svg>
            <TranslatedText text='Add option'/>
          </Button>

      </BlockContainer>
    )
  }
}

const mapStateToProps = ({ blocks, language }, { block }) => {

  const blockValue = initialCustomOptionsBlock(block, blocks);

  return {
    block,
    blockValue,
    language,
  };
};

export default connect(mapStateToProps)(CustomOptions);